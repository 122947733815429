.btn {

  font-family: $ff-poppins;
  text-transform: uppercase;
  letter-spacing: 8px;
  font-weight: 500;
  text-align: center;
}

$btn-circle-size: 6rem;

.btn-circle {
  position: relative;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 8px;
  text-align: left;
  color: $white;
  background: transparent;
  border: 0;

  span {
    position: relative;
    z-index: 10;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 9;
    width: $btn-circle-size;
    height: $btn-circle-size;
    right: -$btn-circle-size*0.2;
    top: 50%;
    margin-top: -$btn-circle-size*0.5;
    border-radius: 50%;
    background: $green;

    @include trans(all, 300ms)
  }

  @media (min-width: $md) {
    &:hover {
      &:after {
        width: $btn-circle-size*1.9;
        height: $btn-circle-size*1.9;
        margin-top: -($btn-circle-size*1.9)*0.5;
        // right: -$btn-circle-size/2;
      }
    }
  }

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.plus_link {

  background: transparent;
  border: 0;

  &:before,
  &:after {
    content: '';
    height: 1.2rem;
    width: 1.2rem;
    display: inline-block;
    background-image: url(../../img/icon_plus.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: sub;
    @include trans(all, 300ms)
  }

  &__before {
    &:before { margin-right: .3rem }
    &:after { content: none }
  }

  &__after {
    &:after { margin-left: .3rem }
    &:before { content: none }
  }

  &:hover {
    text-decoration: none;

    &:before,
    &:after {
      @include trf(scale(1.05))
    }
  }

  &:focus {
    outline: 0;
    box-shadow: none
  }

}

.scroll-arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
  width: 2px;
  height: 80px;
  background: $green;
  opacity: 1;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: -30px;
    width: 16px;
    height: 16px;
    margin-left: -7px;
    border-left: 2px solid $green;
    border-bottom: 2px solid $green;
    transform: rotate(-45deg);

    animation-duration: 16s;
    animation-name: bounce;
    animation-iteration-count: infinite;
    animation-delay: 5s;
  }
}

.line-link {
  position: absolute;
  left: 50%;
  bottom: 120px;
  transform: translateX(-50%);
  text-align: center;
  cursor: pointer;

  @media (min-width: $md) {
    left: 30%;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -80px;
    margin-left: -1px;
    width: 2px;
    height: 40px;
    background: $white;
  }

  &__text {
    color: $white;
    text-transform: uppercase;
    letter-spacing: 5px;

    @include userSelectNone();
  }
}

.btn-invisible {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;

  &:focus {
    outline: 0;
    box-shadow: 0;
  }
}
