
.collapse {

  &__link {
    color: $green;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 5px;
    background: none;
    border: 0;
    padding-bottom: 0;

    -webkit-transition: opacity 200ms;
    -moz-transition: opacity 200ms;
    -ms-transition: opacity 200ms;
    -o-transition: opacity 200ms;
    transition: opacity 200ms;

    &:after {
      content: '';
      position: absolute;
      bottom: calc(50% - 8px);
      left: calc(100% + 16px);
      margin-left: -8px;
      width: 16px;
      height: 16px;
      background-image: url('/img/arrow_down.svg');
      -webkit-background-size: contain;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-transition: all 200ms;
      -moz-transition: all 200ms;
      -ms-transition: all 200ms;
      -o-transition: all 200ms;
      transition: all 200ms;
    }

    &:hover {
      color: darken($green, 5);

      &:after {
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
        transform: translateY(5px);
      }
    }
    &:focus {
      outline: none;
    }

    &.collapsed {
      &:after {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    @media (min-width: $md) {
      padding-bottom: 20px;

      &:after {
        bottom: 0;
        left: 50%;
        margin-left: -8px;
      }
    }
  }

  &__content {
    display: none;

    &.collapsing {
      position: relative;
      height: 0;
      overflow: hidden;
      transition: height .35s ease;
    }

  }

}