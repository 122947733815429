.cookies {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  visibility: hidden;
  z-index: -9999;
  opacity: 0;

  &__inner {
    padding: 10px;
    background: rgba(#fff, .95);

    width: 100%;

    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: row;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $md) {
      flex-direction: row;
    }

    &__text {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      color: #333;
      font-size: 12px;
      line-height: 12px;
      align-self: center;
      margin-bottom: 10px;

      @media (min-width: $md) {
        margin-bottom: 0;
      }
    }

    &__button {
      flex: 0 0 auto;
      width: auto;
      max-width: 100%;
      border: 0;
      background-color: $yellow;
      color: #333;
      display: block;
      cursor: pointer;
      padding-left: 20px;
      padding-right: 20px;

      @media (min-width: $md) {
        margin-left: 10px;
      }

      &:hover {
        background: darken($yellow, 5);
      }
    }
  }

  &.active {
    z-index: 9999;
    visibility: visible;
    animation-name: cookiesFadeIn;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

}

@keyframes cookiesFadeIn {
  from { opacity: 0 }
  to { opacity: 1 }
}
