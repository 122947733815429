.form-control {
  color: $grey-medium;
  padding: .375rem .75rem;
  font-family: $ff-poppins;
  font-weight: 300;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 0;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI4NC45MjkgMjg0LjkyOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjg0LjkyOSAyODQuOTI5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PGc+Cgk8cGF0aCBkPSJNMjgyLjA4Miw3Ni41MTFsLTE0LjI3NC0xNC4yNzNjLTEuOTAyLTEuOTA2LTQuMDkzLTIuODU2LTYuNTctMi44NTZjLTIuNDcxLDAtNC42NjEsMC45NS02LjU2MywyLjg1NkwxNDIuNDY2LDE3NC40NDEgICBMMzAuMjYyLDYyLjI0MWMtMS45MDMtMS45MDYtNC4wOTMtMi44NTYtNi41NjctMi44NTZjLTIuNDc1LDAtNC42NjUsMC45NS02LjU2NywyLjg1NkwyLjg1Niw3Ni41MTVDMC45NSw3OC40MTcsMCw4MC42MDcsMCw4My4wODIgICBjMCwyLjQ3MywwLjk1Myw0LjY2MywyLjg1Niw2LjU2NWwxMzMuMDQzLDEzMy4wNDZjMS45MDIsMS45MDMsNC4wOTMsMi44NTQsNi41NjcsMi44NTRzNC42NjEtMC45NTEsNi41NjItMi44NTRMMjgyLjA4Miw4OS42NDcgICBjMS45MDItMS45MDMsMi44NDctNC4wOTMsMi44NDctNi41NjVDMjg0LjkyOSw4MC42MDcsMjgzLjk4NCw3OC40MTcsMjgyLjA4Miw3Ni41MTF6IiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiNEOUQ5RDkiIGRhdGEtb2xkX2NvbG9yPSIjQ0RDNEM0Ij48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==);
  background-position: 95% center;
  background-repeat: no-repeat;
  background-size: 1em;
  padding-right: 2em;
}

.custom-control-label {
  font-family: $ff-poppins;
  font-weight: 300;
  line-height: 20px;
}

.contact-form {

  input {
    min-height: 40px;
  }

  textarea {
    height: calc((40px * 3) + (1rem * 2));
  }

}

.form-error {
  color: #dc3545;
  display: none;

  &.active {
    display: block;
  }
}

// notify
.postman {

  display: none;
  position: fixed;
  top: 10px;
  left: 50%;
  max-width: calc(100% - 15px);
  transform: translate(-50%, 0);
  padding: 10px 40px;
  background: $green;
  color: #fff;

  animation: postman;
  animation-delay: 5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  &.active {
    z-index: 110;
    display: block;
  }
}