// Media query according to breakpoints declared in settings/breakpoints
@mixin mq($break, $reverse: false) {
    @if $reverse {
        @media only screen and (max-width: #{$break - 1}px) {
            @content;
        }
    }
    @else {
        @media only screen and (min-width: #{$break}px) {
            @content;
        }
    }
}

// Image replacement using the Scott Kellum method
// http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
@mixin image-replacement {
    text-indent: 200%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
}

// Cover element with background image
@mixin image-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

// Contain background image inside element
@mixin image-contain {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

// Cover parent with element
@mixin overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Cover parent with pseudo-element
@mixin pseudo-overlay {
    content: "";
    @include overlay;
}

// Absolutely center element in parent
@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Effectively remove element from page
@mixin hide {
    position: fixed;
    opacity: 0;
    pointer-events: none;
    user-select: none;
}

// Flex IE 11 fix
// "flex: 1" gets interpreted as "flex: 1 0 auto" instead of standard
// "flex 1 auto", this mixin fixes this inconsistency
// WARNING: This fix is not universal and requires testing, "flex: 1 0 auto"
// sometimes is the correct behavior
@mixin flex($grow) {
    flex: $grow;
    -ms-flex: $grow 1 auto;
}

// Clearfix (supports IE 8+)
@mixin clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// Fix blurred or flickering transition effect
// The perspective method is useful when transform is already applied
@mixin transition-fix($perspective: false) {
    @if $perspective {
        perspective: 1000;
    }
    @else {
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
    }
}

// Set padding-top based on intended width/height ratio
// "responsive-ratio(16, 9)" => "padding-top: 56.25%"
@mixin responsive-ratio($x,$y) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    padding-top: $padding;
}

// Create a triangle using borders
@mixin triangle($color, $direction, $size: 6px, $round: false){
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( $size / 2.5 );
    }
    @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( $size / 2.5 );
    }
    @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    }
    @else if  $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

// Set placeholder styles for all browsers
@mixin placeholder {

    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

// Hover effect for non-touch devices only
// Prevents sticky hover effects on touch devices
@mixin mouse-hover {
    @media (hover: hover), (-ms-high-contrast: none), (-moz-touch-enabled: 0) {
        
        &:hover {
            @content;
        }
    }
}

// Apply styles to touch devices only
@mixin touch {
    @media (-moz-touch-enabled: 1), (pointer: coarse), (hover: none) {
        @content;
    }
}

/* MARTINSA mixins */

@function rem-calc($size, $fsz-base:12) {
    $remSize: $size / $fsz-base;
    @return #{$remSize}rem;
}

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
            eot: "?",
            svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
            otf: "opentype",
            ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}


@mixin transDelay($delay) {
    -webkit-transition-delay: $delay;
    -moz-transition-delay: $delay;
    -ms-transition-delay: $delay;
    -o-transition-delay: $delay;
    transition-delay: $delay;
}

// linear|ease|ease-in|ease-out|ease-in-out|step-start|step-end|steps(int,start|end)|cubic-bezier(n,n,n,n)|initial|inherit;
@mixin trans($value, $time, $easing : ease) {
    -webkit-transition: $value $time $easing;
    -moz-transition: $value $time $easing;
    -ms-transition: $value $time $easing;
    -o-transition: $value $time $easing;
    transition: $value $time $easing;
}

@mixin trf($value) {
    -webkit-transform: $value;
    -moz-transform: $value;
    -ms-transform: $value;
    -o-transform: $value;
    transform: $value;
}

@mixin userSelectNone() {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
