#map {
  width: 100%;
  height: 600px;
}

#mapa {
  // position: relative;
  z-index: 3;

  width: 100%;
  height: 600px;

  cursor: grab !important;

  background-color: #5F0F3F;

  svg {
    // background-color: rgba(95, 15, 63, 0.14);
  }
  
}

// .card {
//   box-shadow: 0 4px 16px 0 rgb(95, 15, 63 / 10%) !important;
// }

.smap .compass {
  background: url(/img/compas.png) center center no-repeat #00b98b !important;
  &:hover {
    background: url(/img/compas_hover.png) center center no-repeat #00b98b !important;
  }
}

.smap .zoom {
  button {
    background-color: #00b98b !important;
    color: #5F0F3F !important;

    &:hover {
      color: #F0CD0A !important;
    }
  }
}
