.team {
  position: relative;
  z-index: 4;
  @media (max-width: $lg) {
   padding-bottom: 350px;
  }
}

.pointer-events-none {
  pointer-events: none;
}
.pointer-events-auto {
  pointer-events: auto;
}

.team-images {
  position: absolute;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;

  display: none;

  @media (min-width: $lg) {
    display: block;
  }

  &__left,
  &__right {
    position: absolute;
    bottom: 0;
    max-height: 70%;
  }

  &__left {
    left: -130px;
    @media (min-width: $xl) {
      left: -140px;
    }
  }

  &__right {
    right: -50px;
    @media (min-width: $xl) {
      right: -40px;
    }
  }
}

.team-title {
  color: $yellow;
  pointer-events: none;
  @media (min-width: $lg) {
    color: $white;
    // color: black;
  }
}

.arrow-container {
  position: absolute;
  z-index: 100;

  width: 15%;
  right: 10vw;
  top: calc(-65vh + 350px);

  display: flex;
  justify-content: space-between;
  
  pointer-events: none;

  @media (max-width: $lg) {
    right: 50%;
    transform: translateX(50%);
    top: -70px;
  }
}

.arrow {
  background: none;
  border: none;
  color: $green;
  font-size: 2rem;
  cursor: pointer;
  pointer-events: all;
  outline: none;
  &:focus {
    outline: none;
  }
}
.arrow-left {
  margin-left: 10px;
}
.arrow-right {
  margin-right: 10px;
}

.people {
  height: 375px;

  position: absolute;
  z-index: 1;
  top: -65vh;
  right: 20% ;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  @media (max-width: $lg) {
    height: 300px;
    top: -350px;
  }
}

.man {
  height: 100%;
  cursor: pointer;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &__name{
    font-family: $ff-american-type;
    color: $purple-lighter;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 136.364% */
    letter-spacing: 0.5px;
  }

 img {
  user-select: none;
  pointer-events: none;
  height: 50%;
 }   
}

.movingMask1, .movingCircle1 {
  will-change: transform, cx;
}


