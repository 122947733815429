#footer {
  font-family: $ff-poppins;
  font-weight: 300;
  color: $grey-footer;

  a, button {
    font-weight: 300;
    white-space: nowrap;
    color: $grey-footer;

    &:hover {
      text-decoration: underline;
    }
  }

  .logo-footer {

    img {
      width: 140px;
    }
  }

  .law-plus-wall {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    a {
      img {
        width: 100%;
        max-width: 300px;
      }

    }

  }
}

.logo-footer,
.link-linked-in {
  margin-left: 10px;
  opacity: .2;
  @include trans(opacity, 300ms);

  &:hover {
    opacity: 1;
  }
}
