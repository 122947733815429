.address-person {
  line-height: 1.4;

  span {
    font-weight: 500;
  }

  &--v2 {
    line-height: 1.5rem;
    margin-bottom: 30px;
    h3 {
      margin-bottom: 0.5rem;
    }
    strong {
      font-weight: 700;
    }
  }
}

.introteam {
  &__anim {
    position: absolute;
    top: 55%;
    right: 18.75vw;

    width: 27.71vw;

    svg {
      width: 100%;
      height: auto;
    }
  }
}