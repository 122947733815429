.shape {
  overflow: hidden;
}

.square {
  position: absolute;
  left: 0;
  bottom: 0;
  height: calc(100% + 100vh);
  width: 100%;
  z-index: 0;

  .square__shape-big {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 52%;
    width: auto;
    height: 100vh;
    min-height: 600px;
    min-width: 600px;
  }

  .square__shape-small {
    position: absolute;
    z-index: 20;
    top: 50vh;
    left: 50%;
    width: auto;
    height: 20vh;
    transform: translate(-50%, -50%);
    opacity: .95;
  }

  .square__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 100vh);
    //background: $green;
    background: #00b386;
    opacity: 0;
  }

}

.triangle {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  overflow: hidden;

  &__shape-right,
  &__shape-left {
    position: absolute;
    z-index: 0;
    bottom: 0;
    // max-height: 75%;
  }

  &__shape-left {
    display: none;
    left: 0;
    width: 30vw;
    height: 25%;
    background-image: url('/img/triangle-reverse.png');
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: right bottom;
    background-repeat: no-repeat;

    @media (min-width: $md) {
      display: block;
    }
  }

  &__shape-right {
    right: 0;
    width: 100vw;
    height: 120vh;
    background-image: url('/img/triangle.png');
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    background-position: right bottom;
    background-repeat: no-repeat;

    @media (min-width: $md) {
      width: 70vw;
      height: 120vh;
    }
  }
}

.triangle-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba($purple, 0.98)
}

.oval {
  position: absolute;
  left: 50%;
  bottom: 0;
  height: 200%;
  width: 100%;
  z-index: 3;
  max-width: 1200px;
  transform: translateX(-50%);
  pointer-events: none;





  &__shape-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    
    @media (min-width: $lg) {
      height: 100%;
      overflow: initial;
    }
  }

  &__shape {
    position: absolute;
    z-index: 1;
    height: auto;
    width: 60%;
    left: 50%;
    bottom: 57%;
    transform: translate(-50%, 50%);
    pointer-events: none;

    circle {
      fill: $yellow
    }
  }
  
  &__wallShape {
    position: absolute;
    z-index: 0;
    height: auto;
    width: 60%;
    left: 50%;
    bottom: 57%;
    transform: translate(-50%, 50%);
    pointer-events: none;

    circle {
      fill: $white
    }


    &::before {
      position: absolute;
      width: 50vw;
      height: 100%;
      background-color: white;
      top: 0;
      right: 50%;
      content: "";
    }
  }

  &__shape_small {
    position: absolute;
    z-index: 0;
    height: 10%;
    width: auto;
    right: -14%;
    top: 20%;
    transform: translateX(-50%);
    display: none;

    @media (min-width: $lg) {
      display: block;
    }

    circle {
      fill: $yellow
    }

  }
}