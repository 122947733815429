// Keyframes go here

@-webkit-keyframes bounce {
  0%, 2%, 5%, 8%, 10% { margin-top: 0 }
  4% { margin-top: -20px }
  6% { margin-top: -14px }
}
@keyframes bounce {
  0%, 2%, 5%, 8%, 10% { margin-top: 0 }
  4% { margin-top: -20px }
  6% { margin-top: -14px }
}


@-webkit-keyframes postman {
  0% { transform: translate(-50%, 0) }
  100% { transform: translate(-50%, -500px) }
}
@keyframes postman {
  0% { transform: translate(-50%, 0) }
  100% { transform: translate(-50%, -500px) }
}
