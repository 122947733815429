.person {

  &__image {
    width: 100%;

    @media (min-width: $xl) {
      width: 50vw;
      height: 100vh;
      object-fit: cover;
      object-position: 50% 20%;
    }
  }

  &__textbox {
    padding: 1.5rem 3rem;
  }

  &__button {
    background: transparent;
    border: 0;
    padding: 0;
    margin-bottom: 0.5rem;

    span, h3 {
      position: relative;
      margin: 0;
      text-align: left;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0%;
        height: 2px;
        background: $purple;
        transition: all 300ms;
      }
    }

    &:hover {
      span:after, h3:after {
        width: 100%;
      }
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
