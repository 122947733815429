@each $skey, $sval in $spacingSizes {
  @each $nkey, $nval in $spacingNames {
    .#{$nkey}-#{$skey} { #{$nval} : $sval !important }
  }
}

@each $skey, $sval in $spacingSizes {
  .my-#{$skey} { margin-top: $sval !important; margin-bottom: $sval !important; }
  .mx-#{$skey} { margin-left: $sval !important; margin-right: $sval !important; }
  .py-#{$skey} { padding-top: $sval !important; padding-bottom: $sval !important; }
  .px-#{$skey} { padding-left: $sval !important; padding-right: $sval !important; }
}

@each $bkey, $bval in $breakPoints {
  @each $skey, $sval in $spacingSizes {
    @each $nkey, $nval in $spacingNames {
      @media (min-width: #{$bval}) {
        .#{$nkey}-#{$bkey}-#{$skey} { #{$nval} : $sval !important }
      }
    }
  }
}

@each $bkey, $bval in $breakPoints {
  @each $skey, $sval in $spacingSizes {
    @media (min-width: #{$bval}) {
      .my-#{$bkey}-#{$skey} { margin-top: $sval !important; margin-bottom: $sval !important; }
      .mx-#{$bkey}-#{$skey} { margin-left: $sval !important; margin-right: $sval !important; }
      .py-#{$bkey}-#{$skey} { padding-top: $sval !important; padding-bottom: $sval !important; }
      .px-#{$bkey}-#{$skey} { padding-left: $sval !important; padding-right: $sval !important; }
    }
  }
}

@each $key, $val in $colors {
  .bg-#{$key} { background-color : $val }
  .bg-hover-#{$key} { &:hover { background-color : $val } }
  .color-#{$key} { color : $val }
  .color-#{$key} { &:hover { color : $val } }
}

@each $key, $val in $zeroToTenDecimal {
  .opacity-#{$key} { opacity : $val }
  .opacity-hover-#{$key} { &:hover { opacity : $val } }
}

@each $key, $val in $zeroToTen {
  .z-#{$key} { z-index : $val }
}

.nowrap { white-space: nowrap }
.reset-wrap { white-space: initial!important }

.ovf-a { overflow: auto }
.ovf-h { overflow: hidden }
.ovf-x-h { overflow-x: hidden }

.min-h-md-850 {@media (min-width: $md) { min-height: 850px!important; }}

.lstn { list-style-type: none }

.text-underline { text-decoration: underline }
.hover-text-underline { &:hover { text-decoration: underline } }