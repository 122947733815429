html, body {
	overflow-x: hidden;
}

body {
	font-family: $ff-poppins;
	font-size: 14px;
	line-height: 25px;
	color: $text-color;
}

button {
	color: $text-color;
}

address {
	font-family: $ff-poppins;
	font-weight: 300;
	line-height: 25px;
}

hr {
	border-top: 1px solid $grey;
}
