
.wrapper {}

.main-height {
  min-height: 600px;
  max-height: 1200px;
}

.full-main-height {
  height: 100vh;
  min-height: 600px;
  max-height: 1200px;
}

.full-main-height-lg {
  @media (min-width: $lg) {
    height: 100vh;
    min-height: 600px;
    max-height: 1200px;
  }
}

.full-height {
  height: 100vh;
  min-height: 600px;
}