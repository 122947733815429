#header {
	position: fixed;
	padding-top: 10px;
	padding-bottom: 10px;
	top: 0;
	left: 0;
	width: 100%;
	font-family: $ff-montserrat;
	z-index: 100;

	transition: all 300ms;

	&.scroll-bg {
		background: rgba(255, 255, 255, 0.95);
	}

	&.scroll-hide {
		-webkit-transform: translateY(-110%);
		-moz-transform: translateY(-110%);
		-ms-transform: translateY(-110%);
		-o-transform: translateY(-110%);
		transform: translateY(-110%);
	}

	.logo {
		height: 120px;
	}

	@media (min-width: $lg) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.nav {
	@media (max-width: $lg) {
		position: fixed;
		left: -100%;
		top: 0;
		width: 100%;
		height: 100%;
		background: transparent;

		@include trans(all, 600ms);
	}

	&__links {
		text-align: center;
		padding: 0;
		margin: 0;
		max-width: 400px;
	}

	&__link {
		display: inline-block;
		margin-top: 20px;
		margin-bottom: 20px;

		a {
			position: relative;
			color: $white;
			font-size: 40px;
			font-weight: 600;

			@media (min-width: $lg) {
				color: $black;
				font-size: 14px;
				font-weight: 400;
			}

			&:after {
				content: "";
				position: absolute;
				bottom: -2px;
				left: 50%;
				width: 0;
				height: 2px;
				background: $purple;

				@include trans(all, 300ms);
			}

			&:hover {
				text-decoration: none;

				&:after {
					left: 0;
					width: 100%;
				}
			}
		}
	}
	&__mobile-bg {
		position: absolute;
		z-index: -1;
		left: 50%;
		top: 50%;
		width: 0;
		height: 0;
		background: $green;
		transform: translate(-50%, -50%) rotate(45deg);
		transition: all 600ms;
	}

	&.active {
		@media (max-width: $lg) {
			left: 0;
		}
	}

	&.active & {
		@media (max-width: $lg) {
			left: 0;
			background: $green;
		}

		&__mobile-bg {
			width: 200vh;
			height: 200vh;
		}
	}
}

.nav-controls {
	&__lang {
		opacity: 0;
		transition: all 600ms;

		@media (min-width: $lg) {
			opacity: 1;
		}

		&.active {
			opacity: 1;
		}

		a {
			text-transform: uppercase;
			color: $grey;
			font-weight: 500;

			&.active {
				font-weight: 600;
				color: $black;
			}
		}

		span {
			color: $grey;
		}
	}
}

.nav-toggler {
	position: relative;
	z-index: 10;

	display: inline-block;
	background: transparent;
	border: 0;
	border-radius: 0;
	width: 36px;
	padding: 0;
	cursor: pointer;

	span {
		display: block;
		margin-top: 6px;
		height: 4px;
		background: $black;
		width: 100%;
		transition: all 0.3s cubic-bezier(0.68, -3, 0.265, 1.55);

		&:first-child {
			width: 75%;
		}
	}

	&:focus {
		outline: none;

		span {
			background: #333333;
		}
	}

	@media (min-width: $lg) {
		display: none;
	}

	&.active {
		span {
			background: $white;

			&:first-child {
				margin-left: 25%;
			}
		}
	}
}
