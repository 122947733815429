.sb {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
}

.sb-green::-webkit-scrollbar-thumb {
  background-color: $green;

  &:hover {
    background-color: darken($green, 5);
  }
}
