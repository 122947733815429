a {
  color: $text-color;

  &:hover {
    color: $black;
  }
}

h2 {
  font-family: $ff-walsheim;
  font-weight: 700;
  font-size: 46px;
  line-height: 50px;
  color: $purple;

  @media (min-width: $md) {
    font-size: 72px;
    line-height: 72px;
  }

  &.big {
    font-size: 40px;
    line-height: 44px;

    @media (min-width: $md) {
      font-size: 60px;
      line-height: 60px;
    }

    @media (min-width: $xl) {
      font-size: 100px;
      line-height: 100px;
    }
  }

  &.future {
    margin-left: 1em;

    @media (min-width: $xl) {
      font-size: 80px;
      line-height: 80px;
    }
  }
}

h3 {
  font-family: $ff-american-type;
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 1.4rem;
  letter-spacing: 0.68px;
  color: $purple;
}
h4 {
  font-family: $ff-american-type;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 1.4rem;
  letter-spacing: 0.68px;
  color: $purple;
}

.citation {
  font-family: $ff-american-type;
  font-size: 22px;
  line-height: 30px;

  @media (min-width: $md) {
    font-size: 30px;
    line-height: 40px;
  }
}
