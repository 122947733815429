.modal {
  z-index: -100;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $white;
  overflow: hidden;
  opacity: 0;
  display: none;

  &__content {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: $white;
    z-index: -100;
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 0;

    &.active {
      z-index: 100;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__close {
    z-index: 110;
    position: fixed;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
    background: transparent;
    border-radius: 0;
    border: 0;
    padding: 0;

    background-color: $yellow;
    width: 60px;
    height: 60px;
    border-radius: 60px;

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: $btn-focus-box-shadow;
    }
  

    @media (min-width: $md) {
      right: 2rem;
      top: 2rem;
    }

    svg path {
      transition: all 300ms;
    }

    &:hover {
      svg path {
        fill: white
      }
    }
  }


  &.active {
    display: block;
    z-index: 100;
  }

  .link-linked-in {
    margin: 0;
  }
}

.modal-open {
  overflow: hidden;
}